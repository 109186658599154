import { Application } from "@hotwired/stimulus";
import { registerControllers } from "stimulus-vite-helpers";
import * as Turbo from "@hotwired/turbo";
import * as ActiveStorage from "@rails/activestorage";
import 'react-toastify/dist/ReactToastify.css';
import '../stylesheets/application.tailwind.css';
import '../stylesheets/baloo.css';
import '../stylesheets/otto-custom.css';
import '../stylesheets/new_design.css';
import '../stylesheets/components/_floating_banner.css';
import.meta.globEager("../javascript/custom/*.js");

ActiveStorage.start();

Turbo.session.drive = false;
Turbo.start();

const application = Application.start();
const controllers = import.meta.globEager("../javascript/controllers/*.js");
registerControllers(application, controllers);