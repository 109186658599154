import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["addressText", "locationText", "cityText", "stateText"]

  connect() {
    this.checkSize()
    window.addEventListener('resize', this.checkSize.bind(this))
  }

  checkSize() {
    const addressText = this.addressTextTarget
    const locationText = this.locationTextTarget
    const cityText = this.cityTextTarget
    const stateText = this.stateTextTarget

    addressText.classList.remove('text-2xl', 'md:text-3xl', 'lg:text-4xl')
    locationText.classList.remove('mt-2', 'mt-4', 'mt-6')
    cityText.parentElement.classList.remove('text-2xl', 'md:text-3xl', 'lg:text-4xl')

    const isAddressMultiline = addressText.clientHeight > (window.innerWidth >= 1024 ? 60 : 40)
    const isLocationMultiline = locationText.clientHeight > (window.innerWidth >= 1024 ? 60 : 40)

    if (isAddressMultiline || isLocationMultiline) {
      addressText.classList.add('text-2xl', 'md:text-3xl', 'lg:text-4xl')
      cityText.parentElement.classList.add('text-2xl', 'md:text-3xl', 'lg:text-4xl')
      
      if (isAddressMultiline && isLocationMultiline) {
        locationText.classList.add('mt-6')
      } else {
        locationText.classList.add('mt-4')
      }
    } else {
      locationText.classList.add('mt-2')
    }
  }

  disconnect() {
    window.removeEventListener('resize', this.checkSize.bind(this))
  }
}